import React from 'react';
import styled from 'styled-components';
import PortableText from '../PortableText';

const TableStyles = styled.div`
  padding: var(--margin) 0;

  table {
    /* border: 1px solid var(--grey-dark); */
    table-layout: fixed;
    width: 100%;
  }
  th,
  td {
    /* border-left: 1px solid var(--grey-dark); */
    font-family: var(--font-titles);
    padding: 10px;

    &:first-child {
      border-left: 0;
    }
    *:first-child {
      margin-top: 0;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
  th {
    /* background-color: var(--grey); */
    font-weight: normal;
  }
  td {
    border-top: 1px solid var(--grey-dark);
  }
`;

const Table3Columns = ({ rows }) => {
  return (
    <TableStyles>
      <div className="container">
        <table cellPadding="0" cellSpacing="0" className="table-2-columns">
          {rows.map((row, i) => {
            if (i === 0) {
              return (
                <thead key={`${rows[0]._key}-row-${i}`}>
                  <tr valign={row.valign}>
                    <th>
                      <PortableText
                        blocks={row._rawColumn1.text}
                        className="column"
                      />
                    </th>
                    <th>
                      <PortableText
                        blocks={row._rawColumn2.text}
                        className="column"
                      />
                    </th>
                    <th>
                      <PortableText
                        blocks={row._rawColumn3.text}
                        className="column"
                      />
                    </th>
                  </tr>
                </thead>
              );
            }
            return null;
          })}
          <tbody>
            {rows.map((row, i) => {
              if (i === 0) {
                return null;
              }
              return (
                <tr key={`${rows[i]._key}-row-${i}`} valign={row.valign}>
                  <td>
                    <PortableText
                      blocks={row._rawColumn1.text}
                      className="column"
                    />
                  </td>
                  <td>
                    <PortableText
                      blocks={row._rawColumn2.text}
                      className="column"
                    />
                  </td>
                  <td>
                    <PortableText
                      blocks={row._rawColumn3.text}
                      className="column"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </TableStyles>
  );
};

export default Table3Columns;
