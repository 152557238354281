import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import PortableText from '../PortableText';
import InlineSvg from '../InlineSVG';
import Modal from '../Modal';
import { mq } from '../../styles/breakpoints';
import { addToRefs } from '../../lib/helpers';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, MotionPathPlugin);
}

const StepsStyles = styled.div`
  position: relative;
  text-align: center;

  .section-title {
    background-color: var(--blue-dark);
    color: var(--white);
    padding: var(--margin) 0;
    width: 100%;
  }

  .container {
    .block {
      font-size: 0.9375rem;
      margin-top: 1em;

      > *:first-child {
        margin-top: 0;
      }

      p {
        /* background-color: var(--white); */
        margin: var(--margin) auto;
      }
    }
  }

  .line {
    stroke: #dddddd;
  }

  .moving-bird {
    fill: var(--blue);
    height: 3.75rem;
  }

  ul {
    text-align: left;
    list-style: initial;

    li {
      background: var(--white);
    }
  }

  .step {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 14em auto;
    max-width: 19.75rem;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &--number {
      align-items: center;
      background-color: var(--white);
      border: 1px solid var(--blue-dark);
      border-radius: 3rem;
      display: flex;
      font-size: 2.5rem;
      height: 3.75rem;
      justify-content: center;
      width: 3.75rem;

      ${mq[3]} {
        visibility: hidden;
      }
    }

    &--title {
      /* background-color: var(--white); */
      font-size: 1.5625rem;
      font-weight: 300;
      margin-top: 1.5rem;
    }

    /* &:not(:first-of-type) .step--title::before {
      background-color: var(--blue-dark);
      content: '';
      display: block;
      height: 1px;
      margin-bottom: 1.5rem;
      width: 20.25rem;
    } */

    &--text {
      /* background-color: var(--white); */
    }

    svg {
      width: 11.5rem;
      position: absolute;
      transform: translateX(-50%) translateY(calc(-100% - 1em));

      ${mq[3]} {
        visibility: hidden;
      }
    }

    .step {
      &--1 {
        margin-left: 11em;
        transform: translateY(-0.5em);

        svg {
          top: 4em;
          margin-left: -2.375rem;
          max-width: 90%;
          width: 25.5625rem;
          z-index: -1;
        }
      }

      &--2 {
        margin-right: 5em;
        margin-top: -14em;
      }

      &--3 {
        margin-left: 5.5em;
        margin-top: -11em;
      }

      &--4 {
        margin-right: 18em;
        margin-top: -11em;
      }

      &--5 {
        margin-left: 15em;
        margin-top: -4em;
      }

      &--6 {
        margin-right: 14em;
        margin-top: -4em;
      }
    }
  }

  .button {
    background: var(--white);
    margin-top: 1rem;

    &:hover {
      background: var(--blue);
      border: 1px solid var(--blue-dark);
      color: var(--white);
    }
  }

  .curve {
    display: none;
    left: 50%;
    margin-top: 12.8em;
    margin-bottom: 16em;
    overflow: visible;
    position: relative;
    transform: translateX(-50%);
    width: 46.875rem;
    z-index: -1;
  }

  ${mq[3]} {
    .step {
      position: absolute;
      margin: 0 auto;
      left: 50%;

      &--1 {
        top: 18em;
        transform: translateX(calc(-50% - 15em));
      }

      &--2 {
        top: 31em;
        transform: translateX(calc(-50% + 21em));
      }

      &--3 {
        top: 50em;
        transform: translateX(calc(-50% - 20em));
      }

      &--4 {
        top: 70em;
        transform: translateX(calc(-50% + 15.5em));
      }

      &--5 {
        top: 93em;
        transform: translateX(calc(-50% - 10em));
      }

      &--6 {
        top: 120em;
        transform: translateX(calc(-50% + 13em));
      }
    }

    .curve {
      display: block;
    }
  }
`;

const Steps = ({ title, steps }) => {
  const allSteps = useRef(null);
  const modalRefs = useRef([]);

  //   useEffect(() => {
  //     console.log(modalRefs);
  //   }, []);

  let windowWidth = 0;

  if (typeof window !== 'undefined') {
    windowWidth = window.innerWidth;
  }

  const breakpoint = 1280;
  const [width, setWidth] = useState(windowWidth);

  let status = true;
  if (width > breakpoint) {
    status = true;
  } else {
    status = false;
  }
  const [overBreakPoint, setOverBreakPoint] = useState(status);

  useEffect(() => {
    const handleResizeWindow = () => {
      setWidth(window.innerWidth);
      if (width > breakpoint) {
        setOverBreakPoint(true);
      } else {
        setOverBreakPoint(false);
      }
    };
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [overBreakPoint, width]);

  useEffect(() => {
    const curve = allSteps.current.querySelector('.curve');
    const bird = allSteps.current.querySelector('.moving-bird');
    const step1 = allSteps.current.querySelector('.step--1');
    const number1 = step1.querySelector('.step--number');
    const title1 = step1.querySelector('.step--title');
    const button1 = step1.querySelector('.button');
    const svg1 = step1.querySelector('svg');
    const block1 = step1.querySelector('.block');
    const step2 = allSteps.current.querySelector('.step--2');
    const number2 = step2.querySelector('.step--number');
    const title2 = step2.querySelector('.step--title');
    const button2 = step2.querySelector('.button');
    const svg2 = step2.querySelector('svg');
    const block2 = step2.querySelector('.block');
    const step3 = allSteps.current.querySelector('.step--3');
    const number3 = step3.querySelector('.step--number');
    const title3 = step3.querySelector('.step--title');
    const button3 = step3.querySelector('.button');
    const svg3 = step3.querySelector('svg');
    const block3 = step3.querySelector('.block');
    const step4 = allSteps.current.querySelector('.step--4');
    const number4 = step4.querySelector('.step--number');
    const title4 = step4.querySelector('.step--title');
    const button4 = step4.querySelector('.button');
    const svg4 = step4.querySelector('svg');
    const block4 = step4.querySelector('.block');
    const step5 = allSteps.current.querySelector('.step--5');
    const number5 = step5?.querySelector('.step--number');
    const title5 = step5?.querySelector('.step--title');
    const button5 = step5?.querySelector('.button');
    const svg5 = step5?.querySelector('svg');
    const block5 = step5?.querySelector('.block');
    const step6 = allSteps.current.querySelector('.step--6');
    const number6 = step6?.querySelector('.step--number');
    const title6 = step6?.querySelector('.step--title');
    const button6 = step6?.querySelector('.button');
    const svg6 = step6?.querySelector('svg');
    const block6 = step6?.querySelector('.block');

    const line = allSteps.current.querySelector('.line');

    const stepsHeight = allSteps.current.clientHeight;
    // const stepsTop = allSteps.current.offsetTop;

    if (overBreakPoint) {
      // Display Bird
      gsap.from(bird, {
        scrollTrigger: {
          trigger: step1,
          start: 'top =+60%',
        },
        opacity: 0,
      });

      // Draw SVG line on scroll
      const tl = gsap
        .timeline({
          defaults: {
            ease: 'none',
          },
          paused: true,
        })
        .from(line, { drawSVG: 0 }, 0)
        .to(
          bird,
          {
            motionPath: {
              path: line,
              align: '.line',
              alignOrigin: [0.5, 0.5],
            },
          },
          0
        );

      ScrollTrigger.create({
        trigger: curve,
        scrub: 0.5,
        start: 'top 60%',
        end: `+=${stepsHeight - 80} bottom`,
        // end: `top -=${stepsHeight - stepsTop}`,
        // markers: true,
        onUpdate: ({ progress }) =>
          tl.progress() < progress ? tl.progress(progress) : null,
      });

      // Display steps, step by step
      gsap
        .timeline({
          scrollTrigger: {
            trigger: step1,
            start: 'top =+80%',
            toggleActions: 'play none none none',
          },
        })
        .from(number1, {
          duration: 0.5,
          autoAlpha: 0,
          scale: 0,
          ease: 'elastic(2.5, 1)',
        })
        .from(
          svg1,
          {
            duration: 0.5,
            autoAlpha: 0,
          },
          '-=0.2'
        )
        .from(
          title1,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.2'
        )
        .from(
          block1,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.5'
        )
        .from(
          button1,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.5'
        );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: step2,
            start: 'top =+65%',
            toggleActions: 'play none none none',
          },
        })
        .from(number2, {
          duration: 0.5,
          autoAlpha: 0,
          scale: 0,
          ease: 'elastic(2.5, 1)',
        })
        .from(
          svg2,
          {
            duration: 0.5,
            autoAlpha: 0,
          },
          '-=0.2'
        )
        .from(
          title2,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.2'
        )
        .from(
          block2,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.5'
        )
        .from(
          button2,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.5'
        );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: step3,
            start: 'top =+75%',
            // markers: true,
            toggleActions: 'play none none none',
          },
        })
        .from(number3, {
          duration: 0.5,
          autoAlpha: 0,
          scale: 0,
          ease: 'elastic(2.5, 1)',
        })
        .from(
          svg3,
          {
            duration: 0.5,
            autoAlpha: 0,
          },
          '-=0.2'
        )
        .from(
          title3,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.2'
        )
        .from(
          block3,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.5'
        )
        .from(
          button3,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.5'
        );

      gsap
        .timeline({
          scrollTrigger: {
            trigger: step4,
            start: 'top =+75%',
            toggleActions: 'play none none none',
          },
        })
        .from(number4, {
          duration: 0.5,
          autoAlpha: 0,
          scale: 0,
          ease: 'elastic(2.5, 1)',
        })
        .from(
          svg4,
          {
            duration: 0.5,
            autoAlpha: 0,
          },
          '-=0.2'
        )
        .from(
          title4,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.2'
        )
        .from(
          block4,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.5'
        )
        .from(
          button4,
          {
            duration: 0.7,
            autoAlpha: 0,
            y: 40,
            ease: 'power2.out',
          },
          '-=0.5'
        );

      if (steps?.length >= 5) {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: step5,
              start: 'top =+78%',
              toggleActions: 'play none none none',
            },
          })
          .from(number5, {
            duration: 0.5,
            autoAlpha: 0,
            scale: 0,
            ease: 'elastic(2.5, 1)',
          })
          .from(
            svg5,
            {
              duration: 0.5,
              autoAlpha: 0,
            },
            '-=0.2'
          )
          .from(
            title5,
            {
              duration: 0.7,
              autoAlpha: 0,
              y: 40,
              ease: 'power2.out',
            },
            '-=0.2'
          )
          .from(
            block5,
            {
              duration: 0.7,
              autoAlpha: 0,
              y: 40,
              ease: 'power2.out',
            },
            '-=0.5'
          )
          .from(
            button5,
            {
              duration: 0.7,
              autoAlpha: 0,
              y: 40,
              ease: 'power2.out',
            },
            '-=0.5'
          );
      }

      if (steps?.length >= 6) {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: step6,
              start: 'top =+80%',
              toggleActions: 'play none none none',
            },
          })
          .from(number6, {
            duration: 0.5,
            autoAlpha: 0,
            scale: 0,
            ease: 'elastic(2.5, 1)',
          })
          .from(
            svg6,
            {
              duration: 0.5,
              autoAlpha: 0,
            },
            '-=0.2'
          )
          .from(
            title6,
            {
              duration: 0.7,
              autoAlpha: 0,
              y: 40,
              ease: 'power2.out',
            },
            '-=0.2'
          )
          .from(
            block6,
            {
              duration: 0.7,
              autoAlpha: 0,
              y: 40,
              ease: 'power2.out',
            },
            '-=0.5'
          );
      }
    }
  }, [allSteps, overBreakPoint, steps?.length]);

  function handleModal(el, i) {
    modalRefs.current[i].openModal();
  }

  return (
    <StepsStyles ref={allSteps}>
      <h2 className="section-title">
        <div className="container">{title}</div>
      </h2>
      {steps?.length === 4 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 827.6 930"
          className="curve"
        >
          <path
            d="M144.9,18.7c0,0,652.9-21.8,649.9,282.9 c8.3,266.4-191.4,267-451.2,237.5C83.8,509.7-0.5,577.2,2.8,770.1s430,121.6,650.9,156.2"
            fill="none"
            strokeWidth="5"
            className="line"
          />
          <path
            d="M144.9,18.7 c0,0,652.9-21.8,649.9,282.9c8.3,266.4-191.4,267-451.2,237.5C83.8,509.7-0.5,577.2,2.8,770.1s430,121.6,650.9,156.2"
            fill="none"
            stroke="#fff"
            strokeWidth="8"
            strokeDasharray="10,15"
            className="dotted-line"
          />
          <g className="moving-bird">
            <path
              d="M-189.3-1158.3L-189.3-1158.3L-189.3-1158.3L-189.3-1158.3z"
              transform="translate(321.001 1166.997)"
            />
            <path
              d="M-160.5-1139.1L-160.5-1139.1c-0.1-0.3-0.3-0.7-0.4-1v0c-0.1-0.2-0.3-0.5-0.4-0.7 c-0.3-0.4-0.6-0.8-1-1.1c-0.2-0.2-0.4-0.3-0.6-0.5c-0.6-0.4-1.2-0.7-1.9-0.9c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1 c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.5,0.1c-0.6,0.1-1.2,0.3-1.8,0.6c-0.2,0.1-0.4,0.2-0.6,0.3 c-0.5,0.3-1,0.7-1.4,1.2c-0.3,0.4-0.6,0.8-0.9,1.2c-0.2,0.5-0.4,0.9-0.5,1.4h-2.5l-2.3-5.9c0.7,0,1.3-0.1,1.9-0.2 c8.7-1.6,11.8-8.6,11.8-14.2v-0.3l2.3-2.1l-2.9-0.7c-0.7-1.5-1.8-2.8-3.2-3.7c-3.1-1.8-6.9-1.8-10-0.1c-2.2,1-3.8,3-4.4,5.3 c-0.1,0-0.2,0-0.3,0c-2.3,0.1-4.5,0.6-6.6,1.4l-0.8,0.3l0.3,0.8c0.7,1.9,1.8,3.7,3.2,5.1c-1.3,0.6-2.7,0.9-4.2,0.8l-1.1-0.2 l0.6,0.9c2.5,3.3,6.1,5.6,10.1,6.5l1.2,6.1h-11.7v3.4h1.8v4.9h2.3v-4.9h1.4v3.5h2.3v-3.5h10.6c0.3,1.1,0.9,2.2,1.7,3 c0.8,0.8,1.9,1.4,3,1.7c0.3,0.1,0.7,0.1,1,0.2c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.1 c1-0.3,1.9-0.8,2.7-1.6c0.5-0.5,0.9-1,1.3-1.6c0.2-0.3,0.3-0.6,0.4-1C-160.1-1136.3-160.1-1137.8-160.5-1139.1L-160.5-1139.1z M-169-1162c0.8,0,1.4,0.7,1.4,1.4c0,0.8-0.7,1.4-1.4,1.4c-0.8,0-1.4-0.7-1.4-1.4c0-0.4,0.2-0.7,0.4-1 C-169.7-1161.9-169.3-1162-169-1162z M-188.9-1158.2L-188.9-1158.2L-188.9-1158.2L-188.9-1158.2z M-184.4-1153.3 c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-1.1-1.1-2-2.4-2.7-3.9c1.7-0.6,3.5-0.9,5.3-1c0.2,0,0.3,0,0.5,0l0.5,0 c3,0.1,5.9,1.2,8.2,3.2c-1.4,2.4-4,3.9-6.8,3.8C-181.7-1151.8-183.2-1152.3-184.4-1153.3z M-178.9-1138.7l-1.1-5.9 c0.4,0,0.7,0.1,1.1,0.1l2.3,5.9H-178.9z M-166.7-1133.8c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2 c0,0,0,0,0,0C-163.5-1135.3-165-1133.8-166.7-1133.8z"
              transform="translate(321.001 1166.997)"
            />
            <path
              d="M-189.3-1158.3L-189.3-1158.3L-189.3-1158.3L-189.3-1158.3z"
              transform="translate(321.001 1166.997)"
            />
          </g>
        </svg>
      )}
      {steps?.length === 5 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 829.8 1352.6"
          className="curve"
        >
          <path
            d="M146.1,18.6c0,0,652.9-21.8,649.9,282.9	c8.3,266.4-191.4,267-451.2,237.5S0.8,577.1,4.1,770s430,121.6,650.9,156.2s207.7,287.4,73,374.5c-115.3,71.6-277.1,37.3-505.4,48"
            fill="none"
            strokeWidth="5"
            className="line"
          />
          <path
            d="M146.1,18.6	c0,0,652.9-21.8,649.9,282.9c8.3,266.4-191.4,267-451.2,237.5S0.8,577.1,4.1,770s430,121.6,650.9,156.2s207.7,287.4,73,374.5	c-115.3,71.6-277.1,37.3-505.4,48"
            fill="none"
            stroke="#fff"
            strokeWidth="8"
            strokeDasharray="10,15"
            className="dotted-line"
          />
          <g className="moving-bird">
            <path d="M136.8,8.9L136.8,8.9L136.8,8.9L136.8,8.9z" />
            <path d="M165.5,28L165.5,28c-0.1-0.3-0.3-0.7-0.4-1l0,0c-0.1-0.2-0.3-0.5-0.4-0.7c-0.3-0.4-0.6-0.8-1-1.1 c-0.2-0.2-0.4-0.3-0.6-0.5c-0.6-0.4-1.2-0.7-1.9-0.9c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.7,0 c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.5,0.1c-0.6,0.1-1.2,0.3-1.8,0.6c-0.2,0.1-0.4,0.2-0.6,0.3c-0.5,0.3-1,0.7-1.4,1.2 c-0.3,0.4-0.6,0.8-0.9,1.2c-0.2,0.5-0.4,0.9-0.5,1.4h-2.5l-2.3-5.9c0.7,0,1.3-0.1,1.9-0.2c8.7-1.6,11.8-8.6,11.8-14.2V7.9l2.3-2.1 l-3-0.7c-0.7-1.5-1.8-2.8-3.2-3.7c-3.1-1.8-6.9-1.8-10-0.1c-2.2,1-3.8,3-4.4,5.3c-0.1,0-0.2,0-0.3,0c-2.3,0.1-4.5,0.6-6.6,1.4 L136,8.3l0.3,0.8c0.7,1.9,1.8,3.7,3.2,5.1c-1.3,0.6-2.7,0.9-4.2,0.8l-1.1-0.2l0.6,0.9c2.5,3.3,6.1,5.6,10.1,6.5l1.2,6.1h-11.7v3.4 h1.8v4.9h2.3v-4.9h1.4v3.5h2.3v-3.5h10.6c0.3,1.1,0.9,2.2,1.7,3s1.9,1.4,3,1.7c0.3,0.1,0.7,0.1,1,0.2c0.2,0,0.4,0,0.5,0 c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.1c1-0.3,1.9-0.8,2.7-1.6c0.5-0.5,0.9-1,1.3-1.6c0.2-0.3,0.3-0.6,0.4-1 C166,30.9,166,29.4,165.5,28L165.5,28z M157.1,5.1c0.8,0,1.4,0.7,1.4,1.4s-0.7,1.4-1.4,1.4c-0.8,0-1.4-0.7-1.4-1.4 S156.3,5.1,157.1,5.1L157.1,5.1z M137.2,8.9L137.2,8.9L137.2,8.9L137.2,8.9z M141.7,13.9c-0.1-0.1-0.3-0.2-0.4-0.3 c-0.1-0.1-0.2-0.2-0.3-0.3c-1.1-1.1-2-2.4-2.7-3.9c1.7-0.6,3.5-0.9,5.3-1c0.2,0,0.3,0,0.5,0h0.5c3,0.1,5.9,1.2,8.2,3.2 c-1.4,2.4-4,3.9-6.8,3.8C144.3,15.4,142.8,14.8,141.7,13.9z M147.2,28.4l-1.1-5.9c0.4,0,0.7,0.1,1.1,0.1l2.3,5.9L147.2,28.4z M159.3,33.3c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2l0,0C162.5,31.9,161.1,33.3,159.3,33.3L159.3,33.3z" />
            <path d="M136.8,8.9L136.8,8.9L136.8,8.9L136.8,8.9z" />
          </g>
        </svg>
      )}
      {steps?.length === 6 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 827.6 1829.3"
          className="curve"
        >
          <path
            d="M145.4,18.6c0,0,652.9-21.8,649.9,282.9c8.3,266.4-191.4,267-451.2,237.5	S0.1,577.1,3.4,770s430,121.6,650.9,156.2s207.7,287.4,73,374.5c-137.8,85.5-370.3,34.3-478.6,43.7	c-216,26.9-252.8,164.7-245.3,254.5c30.4,301.2,650.9,217,650.9,217"
            fill="none"
            strokeWidth="5"
            className="line"
          />
          <path
            d="M145.4,18.6c0,0,652.9-21.8,649.9,282.9	c8.3,266.4-191.4,267-451.2,237.5S0.1,577.1,3.4,770s430,121.6,650.9,156.2s207.7,287.4,73,374.5c-137.8,85.5-370.3,34.3-478.6,43.7	c-216,26.9-252.8,164.7-245.3,254.5c30.4,301.2,650.9,217,650.9,217"
            fill="none"
            stroke="#fff"
            strokeWidth="8"
            strokeDasharray="10,15"
            className="dotted-line"
          />
          <g className="moving-bird">
            <path d="M135.7,8.8L135.7,8.8L135.7,8.8L135.7,8.8z" />
            <path d="M164.4,27.9L164.4,27.9c-0.1-0.3-0.3-0.7-0.4-1l0,0c-0.1-0.2-0.3-0.5-0.4-0.7 c-0.3-0.4-0.6-0.8-1-1.1c-0.2-0.2-0.4-0.3-0.6-0.5c-0.6-0.4-1.2-0.7-1.9-0.9c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1 c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.5,0.1c-0.6,0.1-1.2,0.3-1.8,0.6c-0.2,0.1-0.4,0.2-0.6,0.3 c-0.5,0.3-1,0.7-1.4,1.2c-0.3,0.4-0.6,0.8-0.9,1.2c-0.2,0.5-0.4,0.9-0.5,1.4h-2.5l-2.3-5.9c0.7,0,1.3-0.1,1.9-0.2 c8.7-1.6,11.8-8.6,11.8-14.2V7.8l2.3-2.1l-3-0.7c-0.7-1.5-1.8-2.8-3.2-3.7c-3.1-1.8-6.9-1.8-10-0.1c-2.2,1-3.8,3-4.4,5.3 c-0.1,0-0.2,0-0.3,0c-2.3,0.1-4.5,0.6-6.6,1.4l-0.8,0.3l0.3,0.8c0.7,1.9,1.8,3.7,3.2,5.1c-1.3,0.6-2.7,0.9-4.2,0.8l-1.1-0.2 l0.6,0.9c2.5,3.3,6.1,5.6,10.1,6.5l1.2,6.1h-11.7v3.4h1.8v4.9h2.3v-4.9h1.4v3.5h2.3v-3.5h10.6c0.3,1.1,0.9,2.2,1.7,3s1.9,1.4,3,1.7 c0.3,0.1,0.7,0.1,1,0.2c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.1c1-0.3,1.9-0.8,2.7-1.6 c0.5-0.5,0.9-1,1.3-1.6c0.2-0.3,0.3-0.6,0.4-1C164.9,30.8,164.9,29.3,164.4,27.9L164.4,27.9z M156,5c0.8,0,1.4,0.7,1.4,1.4 s-0.7,1.4-1.4,1.4c-0.8,0-1.4-0.7-1.4-1.4S155.2,5,156,5L156,5z M136.1,8.8L136.1,8.8L136.1,8.8L136.1,8.8z M140.6,13.8 c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-1.1-1.1-2-2.4-2.7-3.9c1.7-0.6,3.5-0.9,5.3-1c0.2,0,0.3,0,0.5,0h0.5 c3,0.1,5.9,1.2,8.2,3.2c-1.4,2.4-4,3.9-6.8,3.8C143.2,15.3,141.7,14.7,140.6,13.8z M146.1,28.3l-1.1-5.9c0.4,0,0.7,0.1,1.1,0.1 l2.3,5.9L146.1,28.3z M158.2,33.2c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2l0,0 C161.4,31.8,160,33.2,158.2,33.2L158.2,33.2z" />
            <path d="M135.7,8.8L135.7,8.8L135.7,8.8L135.7,8.8z" />
          </g>
        </svg>
      )}
      <div className="container">
        {steps.map((step, i) => (
          <div
            className={`step step--${steps.indexOf(step) + 1}`}
            key={step._key}
          >
            <InlineSvg src={step.illustration} />
            <div className="step--number">
              {step.additionalService ? '+' : steps.indexOf(step) + 1}
            </div>
            <h3 className="step--title">
              <div className="container">{step.title}</div>
            </h3>
            <button
              type="button"
              onClick={(el) => handleModal(el, i)}
              className="button"
            >
              En savoir +
            </button>
          </div>
        ))}
      </div>
      {steps.map((step, i) => (
        <Modal
          key={`modal-${i}`}
          ref={(el) => addToRefs(modalRefs, el)}
          id={`modal-${step._key}`}
        >
          <PortableText
            blocks={step.stepText._rawText}
            className="step--text"
          />
        </Modal>
      ))}
    </StepsStyles>
  );
};

export default Steps;
