/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/display-name */
import React from 'react';
import { graphql } from 'gatsby';
import { MapToComponents } from 'react-map-to-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ThreeColumns from '../components/sections/ThreeColumns';
import TwoColumns from '../components/sections/TwoColumns';
import SingleColumn from '../components/sections/SingleColumn';
import Steps from '../components/sections/Steps';
import Hero from '../components/sections/Hero';
import SEO from '../components/SEO';
import IntroHome from '../components/IntroHome';
import Testimony from '../components/sections/Testimony';
import ContactForm from '../components/ContactForm';
import BlockQuestions from '../components/sections/BlockQuestions';
import More from '../components/sections/More';
import Table from '../components/sections/Table';
import Table2Columns from '../components/sections/Table2Columns';
import Table3Columns from '../components/sections/Table3Columns';
import TwoColumnsQuery from '../fragments/TwoColumns';
import ThreeColumnsQuery from '../fragments/ThreeColumns';
import SingleColumnQuery from '../fragments/SingleColumn';
import HeroQuery from '../fragments/Hero';
import BlockQuestionsQuery from '../fragments/BlockQuestions';
import MoreQuery from '../fragments/More';
import TableauQuery from '../fragments/Tableau';
import Table2ColumnsQuery from '../fragments/Table2Columns';
import Table3ColumnsQuery from '../fragments/Table3Columns';
import ContactVideo from '../assets/videos/early-birds-contact.mp4';

export default function SinglePage({
  pageContext,
  location,
  data: { singlePage },
}) {
  const { title, displayTitle, content, titleSeo, descriptionSeo, image } =
    singlePage;

  return (
    <>
      <SEO
        title={titleSeo || title}
        location={location}
        description={descriptionSeo}
      />

      {pageContext.slug === 'home' && <IntroHome />}

      {pageContext.slug === 'nous-contacter' && (
        <div className="container">
          <video autoPlay loop muted playsInline width="100%">
            <source src={ContactVideo} type="video/mp4" />
          </video>
        </div>
      )}

      {pageContext.slug !== 'home' && image?.asset && (
        <div className="container">
          <GatsbyImage
            image={getImage(image.asset)}
            alt={image.altText || title}
            className="image-header"
          />
        </div>
      )}

      {pageContext.slug !== 'home' && displayTitle && (
        <div className="container title-container">
          <h1 className="section-title">{title}</h1>
        </div>
      )}

      <MapToComponents
        getKey={(section) => section.id || section._key}
        getType={(section) => section._type}
        list={content}
        map={{
          threeColumns: ThreeColumns,
          twoColumns: TwoColumns,
          singleColumn: SingleColumn,
          hero: Hero,
          steps: Steps,
          testimonySection: Testimony,
          blockQuestions: BlockQuestions,
          more: More,
          tableau: Table,
          table2columns: Table2Columns,
          table3columns: Table3Columns,
        }}
        mapDataToProps={{
          threeColumns: ({ data }) => ({
            title: data.title,
            bgTitle: data.bgTitle?.value,
            bgSection: data.bgSection?.value,
            showTitle: data.showTitle,
            cards: data.cards,
            titleOptions: data.titleOptions,
          }),
          twoColumns: ({ data }) => ({
            alignContent: data.alignContent,
            title: data.title,
            showTitle: data.showTitle,
            bgColor: data.bgColor?.value,
            cards: data.cards,
            colsProportions: data.colsProportions,
            ctaButton: data.ctaButton,
            titleOptions: data.titleOptions,
          }),
          singleColumn: ({ data }) => ({
            background: data.background?.value,
            alignCenter: data.alignCenter,
            ctaButton: data.ctaButton,
            description: data.columnDescription?._rawText,
            title: data.title,
            showTitle: data.showTitle,
            titleOptions: data.titleOptions,
          }),
          hero: ({ data }) => ({
            pageTitle: title,
            images: data.images,
          }),
          steps: ({ data }) => ({
            title: data.title,
            steps: data.steps,
          }),
          testimonySection: ({ data }) => ({
            testimonies: data.testimonyRef,
            cta: data.cta,
            ctaBg: data.ctaBg?.value,
            ctaBgHover: data.ctaBghover?.value,
            ctaColor: data.ctaColor?.value,
            ctaColorHover: data.ctaColorHover?.value,
          }),
          blockQuestions: ({ data }) => ({
            background: data.background?.value,
            questions: data.questionsList,
            showTitle: data.showTitle,
            title: data.title,
            titleOptions: data.titleOptions,
          }),
          more: ({ data }) => ({
            text: data.moreText,
            ctaButton: data.ctaButton,
          }),
          tableau: ({ data }) => ({
            rows: data._rawDataTable?.rows,
          }),
          table2columns: ({ data }) => ({
            rows: data.rows,
          }),
          table3columns: ({ data }) => ({
            rows: data.rows,
          }),
        }}
      />

      {pageContext.slug === 'nous-contacter' && <ContactForm />}
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    singlePage: sanityPage(slug: { current: { eq: $slug } }) {
      title
      displayTitle
      titleMenu
      titleSeo
      descriptionSeo
      image {
        asset {
          altText
          gatsbyImageData(width: 900)
        }
      }
      content {
        ...SingleColumnQuery
        ...TwoColumnsQuery
        ...ThreeColumnsQuery
        ...HeroQuery
        ...MoreQuery
        ...BlockQuestionsQuery
        ...TableauQuery
        ...Table2ColumnsQuery
        ...Table3ColumnsQuery
        ... on SanitySteps {
          _key
          _type
          title
          steps {
            _key
            _type
            title
            additionalService
            illustration
            stepText {
              _rawText(resolveReferences: { maxDepth: 10 })
            }
          }
        }
        ... on SanityTestimonySection {
          _key
          _type
          cta
          ctaBg {
            value
          }
          ctaBghover {
            value
          }
          ctaColor {
            value
          }
          ctaColorHover {
            value
          }
          testimonyRef {
            _key
            _type
            id
            slug {
              current
            }
            author
            testimonyExcerpt {
              _rawText(resolveReferences: { maxDepth: 10 })
            }
            place
          }
        }
      }
    }
  }
`;
