import React from 'react';
import styled from 'styled-components';
import { mq } from '../styles/breakpoints';

const ContactFormStyles = styled.form`
  background-color: var(--blue);
  padding: var(--padding) 0;
  position: relative;
  z-index: 3;

  .rgpd label {
    color: var(--white);
    display: block;
    font-size: 0.8125rem;
  }
  input[type='text'],
  input[type='email'],
  textarea {
    border: 1px solid var(--brownlighter);
    color: var(--brown);
    padding: 14px 16px;
    width: 100%;
  }
  .inputs-list {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 1em;

    li {
      margin-bottom: 1em;
      width: 100%;

      ${mq[0]} {
        width: calc(50% - 0.5em);
      }
    }
  }
  textarea {
    height: 13rem;
    margin-top: 1.5em;
    resize: none;
  }
  p {
    margin-top: 1.5em;
  }
  .form-header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    p {
      margin: 0;
      text-align: center;
    }
    .button {
      margin: 0.5rem auto 0;
    }

    ${mq[0]} {
      flex-wrap: nowrap;
      p {
        text-align: left;
      }
      .button {
        margin: 0 0 0 1.25rem;
      }
    }
  }
  .form-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 4.5rem;
    padding-top: 2.125rem;
  }

  .notabene {
    color: var(--white);
    font-size: 0.875rem;
    * {
      margin: 0;
    }
    a {
      color: var(--white);
      text-decoration: underline;

      &:hover {
        color: var(--white);
      }
    }
  }
`;

export default function ContactForm() {
  return (
    <ContactFormStyles
      action="/merci/"
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <div className="container">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <ul className="inputs-list">
          <li>
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Prénom"
              required
            />
          </li>
          <li>
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Nom"
              required
            />
          </li>
          <li>
            <input type="text" name="tel" id="tel" placeholder="Téléphone" />
          </li>
          <li>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="E-mail"
              required
            />
          </li>
        </ul>
        <textarea name="message" id="message" placeholder="Message" required />
        <div className="rgpd">
          <input type="checkbox" name="rgpd" id="rgpd" required />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="rgpd">
            J'accepte que les informations saisies soient utilisées par la
            société Early Birds pour me répondre.
          </label>
        </div>
        <div className="form-footer">
          <button type="submit" className="button button__second">
            <span>Envoyer mon message</span>
          </button>
        </div>
        <div className="notabene">
          <p>
            Conformément à la réglementation en vigueur sur les données
            personnelles, vous bénéficiez à tout moment d’un droit d’accès,
            d’interrogation, de portabilité, de rectification, d’effacement de
            vos données personnelles, de limitation du traitement de celles-ci
            ainsi que d'un droit de définir vos directives post-mortem. Vous
            disposez également d'un droit d’opposition au traitement de vos
            données personnelles pour des motifs légitimes et d'un droit de
            saisir l’autorité de contrôle compétente afin d’y introduire une
            réclamation. Pour exercer vos droits, vous pouvez adresser une
            demande à la société Early Birds, par courrier à l’adresse
            suivante&nbsp;: 24, rue d'Aumale - 75009 Paris, ou par e-mail envoyé
            à&nbsp;
            <a href="mailto:sandra.baduel@earlybirds.paris">
              sandra.baduel@earlybirds.paris
            </a>
            .
          </p>
        </div>
      </div>
    </ContactFormStyles>
  );
}
