/* eslint-disable react/display-name */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';
import { mq } from '../styles/breakpoints';
import Portal from './Portal';

const ModalStyles = styled.div`
  /* Modal */
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
  }

  .modal-content {
    background-color: var(--blue);
    border-radius: var(--radius);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: var(--white);
    font-size: 1.125rem;
    left: 50%;
    height: 90vh;
    overflow: hidden;
    position: relative;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 37.5rem;
    z-index: 101;

    .button {
      margin: 0 auto;

      svg {
        margin-left: 0.4375rem;
      }
    }

    ${mq[1]} {
      height: auto;
      /* min-height: 40%; */
    }
  }

  .inner {
    height: auto;
    overflow: hidden;
    padding: 1.875rem 30px;

    .block *:first-child {
      margin-top: 0;
    }

    ${mq[1]} {
      padding: 1.875rem 60px;
    }
  }

  .close {
    background: none;
    border: none;
    color: var(--white);
    cursor: pointer;
    font-size: 1.3125rem;
    position: fixed;
    right: 13px;
    top: 13px;

    strong {
      font-weight: 500;
    }
  }
`;

const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);
  const { id } = props;

  const handleOpen = () => {
    setDisplay(true);
  };

  const handleClose = () => {
    setDisplay(false);
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleOpen(),
    closeModal: () => handleClose(),
  }));

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  if (display) {
    return (
      <Portal>
        <ModalStyles className={`modal-wrapper ${id}`}>
          <div className="modal-backdrop" />
          <div className="modal-content">
            <div className="inner">{props.children}</div>
            <button type="button" className="close" onClick={handleClose}>
              <strong>X</strong>
              <span className="sr-only">Fermer</span>
            </button>
          </div>
        </ModalStyles>
      </Portal>
    );
  }

  return null;
});

export default Modal;
