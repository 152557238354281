import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import styled from 'styled-components';
import FirstlineIllus from '../assets/images/home-illustration-firstline.inline.svg';
import FirstlineIllusMobile from '../assets/images/intro-mobile.inline.svg';
import Fontaine from '../assets/images/fontaine.inline.svg';
import Cycliste from '../assets/images/cycliste.inline.svg';
import Bosquet from '../assets/images/bosquet.inline.svg';
import BoucheMetro from '../assets/images/bouche-metro.inline.svg';
import Poussette from '../assets/images/pere-poussette.inline.svg';
import { mq } from '../styles/breakpoints';
import InlineSvg from './InlineSVG';
import { pxtopc } from '../styles/Mixins';

const IntroHomeStyles = styled.div`
  .container {
    max-width: 73.75rem;
  }
  .first-raw-illustrations {
    display: flex;
    justify-content: center;

    ${mq[1]} {
      padding-top: 0.875rem;
    }
  }
  .inner {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;

    ${mq[1]} {
      margin-top: 0;
    }
  }
  .illustr {
    position: relative;

    .mobile {
      margin: auto;
    }

    &--right,
    &--left {
      display: flex;
      justify-content: space-between;
      width: 100%;

      ${mq[2]} {
        display: block;
        width: ${pxtopc(343, 1180)};
      }
    }
    &--right {
      ${mq[1]} {
        display: none;
      }
      ${mq[2]} {
        display: block;
        order: 3;
      }
    }
    &--left {
      ${mq[1]} {
        order: 2;
      }
      ${mq[2]} {
        order: 1;
      }
    }
  }
  .site-intro {
    margin-top: clamp(-1.2rem, -3vh, 0rem);
    text-align: center;
    width: 100%;

    ${mq[1]} {
      order: 1;
    }

    ${mq[2]} {
      order: 2;
      width: ${pxtopc(494, 1180)};
    }
  }
  .section-title {
    font-size: 1.5rem;
    margin-bottom: clamp(1.2rem, 3vh, 1.5625rem);

    ${mq[1]} {
      font-size: clamp(2rem, 5vh, 2.5rem);
    }
  }
  .localization {
    font-family: var(--font);
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    line-height: calc(30 / 24);
    letter-spacing: 0;
    margin-bottom: clamp(1.2rem, 3vh, 1.5625rem);

    ${mq[1]} {
      font-size: clamp(1.2rem, 3vh, 1.5rem);
    }
  }

  .svg {
    ${mq[2]} {
      position: absolute;

      &.fontaine {
        left: 5.875rem;
        top: 3.0625rem;
      }
      &.bosquet {
        left: 1rem;
        top: 21.375rem;
      }
      &.cycliste {
        left: 13.0625rem;
        top: 13.0625rem;

        ${mq[2]} {
          left: 8.0625rem;
        }
        ${mq[3]} {
          left: 13.0625rem;
        }
      }
      &.bouche {
        right: 6.5625rem;
        top: 0.8125rem;
      }
      &.poussette {
        left: -1.1875rem;
        top: 10rem;
      }
    }
  }
`;

const SecondNavStyles = styled.nav`
  border-top: 1.5px solid var(--blue-dark);
  border-bottom: 1.5px solid var(--blue-dark);
  margin: 0 auto 1rem;
  max-width: 20.25rem;
  min-height: 7.1875rem;

  ul {
    background: var(--white);
    display: flex;
    z-index: 3;
  }

  a {
    .text {
      /* display: none; */
      display: block;
      margin-top: 0.5rem;
    }
  }

  ${mq[2]} {
    margin: 0 auto 5rem;

    li {
      margin-bottom: clamp(0.8rem, 3vh, 2rem);
      margin-left: auto;
      margin-right: auto;
      margin-top: clamp(1rem, 3vh, 2.1875rem);
    }

    .text {
      margin-top: 0.8125rem;
      display: block !important;
    }
  }
`;

export default function IntroHome() {
  const { siteData, secondNav } = useStaticQuery(
    graphql`
      query {
        siteData: sanitySingletonSite(_id: { eq: "singletonSite" }) {
          title
          subtitle
          description
        }
        secondNav: sanitySingletonSite(_id: { eq: "singletonSite" }) {
          secondNavigation {
            title
            icon
            linkPage {
              slug {
                current
              }
            }
          }
        }
      }
    `
  );

  const introRef = useRef(null);

  return (
    <IntroHomeStyles className="intro-home" ref={introRef}>
      <div className="first-raw-illustrations container">
        <FirstlineIllus className="desktop" />
      </div>
      <div className="inner container">
        <div className="illustr illustr--left">
          <Fontaine className="fontaine svg desktop" />
          <Bosquet className="bosquet svg desktop" />
          <Cycliste className="cycliste svg desktop" />
        </div>
        <div className="site-intro">
          <h1 className="site-title">{siteData.title}</h1>
          <h2 className="section-title">{siteData.subtitle}</h2>
          <h3 className="localization">{siteData.description}</h3>
          <SecondNavStyles>
            <ul className="second-nav">
              {secondNav.secondNavigation.map((item, index) => (
                <li key={`tab-${index}`}>
                  <Link
                    to={`/${item.linkPage.slug.current}/`}
                    activeClassName="active"
                  >
                    <div className="icon-bg">
                      <InlineSvg src={item.icon} />
                    </div>
                    {item.title && <span className="text">{item.title}</span>}
                  </Link>
                </li>
              ))}
            </ul>
          </SecondNavStyles>
        </div>
        <div className="illustr illustr--right">
          <BoucheMetro className="bouche svg desktop" />
          <Poussette className="poussette svg desktop" />
          <FirstlineIllusMobile className="mobile" />
        </div>
      </div>
    </IntroHomeStyles>
  );
}
